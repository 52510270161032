import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import {
  Flex,
  IconButton,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Select,
  Text,
  Tooltip,
} from '@chakra-ui/react'

import debounce from 'lodash.debounce'

type TablePaginationProps = {
  selectedPage: number
  setSelectedPage: React.Dispatch<React.SetStateAction<number>>
  pageIndex: number
  pageSize: number
  setSelectedPageSize: React.Dispatch<React.SetStateAction<number>>
  totalPageCount: number
  pageSizeOptions: number[]
}

const TablePagination = ({
  selectedPage,
  setSelectedPage,
  pageIndex,
  pageSize,
  setSelectedPageSize,
  totalPageCount,
  pageSizeOptions,
}: TablePaginationProps) => {
  const handleUserPageInput = (value) => {
    const page = value ? value : 1
    setSelectedPage(page)
  }
  const debouncedHandleUserPageInput = debounce(handleUserPageInput, 500)

  return (
    <Flex alignItems="center" justifyContent="space-between" m={4}>
      <Flex>
        <Tooltip label="First Page">
          <IconButton
            aria-label="first_page"
            icon={<ArrowLeftIcon h={3} w={3} />}
            isDisabled={selectedPage === 1}
            mr={4}
            onClick={() => setSelectedPage(1)}
          />
        </Tooltip>

        <Tooltip label="Previous Page">
          <IconButton
            aria-label="previous_page"
            icon={<ChevronLeftIcon h={6} w={6} />}
            isDisabled={selectedPage === 1}
            onClick={() => setSelectedPage((s) => (s === 0 ? 0 : s - 1))}
          />
        </Tooltip>
      </Flex>

      <Flex alignItems="center" margin="12px">
        <Text flexShrink="0" fontSize={'sm'} mr={8}>
          Page{' '}
          <Text as="span" fontSize={'sm'} fontWeight="bold">
            {pageIndex}
          </Text>{' '}
          of{' '}
          <Text as="span" fontSize={'sm'} fontWeight="bold">
            {totalPageCount}
          </Text>
        </Text>
        <Text flexShrink="0" fontSize={'sm'}>
          Go to page:
        </Text>{' '}
        <NumberInput
          defaultValue={selectedPage ?? 1}
          max={totalPageCount}
          min={1}
          ml={2}
          mr={8}
          onChange={debouncedHandleUserPageInput}
          w={28}
        >
          <NumberInputField fontSize={'sm'} />

          <NumberInputStepper>
            <NumberIncrementStepper />

            <NumberDecrementStepper />
          </NumberInputStepper>
        </NumberInput>
        <Select
          fontSize={'sm'}
          onChange={(e) => {
            setSelectedPage(1)
            setSelectedPageSize(Number(e.target.value))
          }}
          value={pageSize}
          w={32}
        >
          {pageSizeOptions.map((pageSize) => (
            <option key={pageSize} value={pageSize}>
              Show {pageSize}
            </option>
          ))}
        </Select>
      </Flex>

      <Flex>
        <Tooltip label="Next Page">
          <IconButton
            aria-label="next_page"
            icon={<ChevronRightIcon h={6} w={6} />}
            isDisabled={selectedPage === totalPageCount}
            onClick={() => setSelectedPage((s) => Number(s) + 1)}
          />
        </Tooltip>

        <Tooltip label="Last Page">
          <IconButton
            aria-label="last_page"
            icon={<ArrowRightIcon h={3} w={3} />}
            isDisabled={selectedPage === totalPageCount}
            ml={4}
            onClick={() => setSelectedPage(totalPageCount)}
          />
        </Tooltip>
      </Flex>
    </Flex>
  )
}

export default TablePagination
